import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onSubmit"]
const _hoisted_2 = { class: "d-flex flex-column p-5 pb-0" }
const _hoisted_3 = { class: "d-flex flex-row flex-lg-nowrap flex-sm-wrap gap-3" }
const _hoisted_4 = { class: "d-flex flex-column p-5 pb-0" }
const _hoisted_5 = { class: "d-flex flex-row flex-lg-nowrap flex-sm-wrap gap-3" }
const _hoisted_6 = { class: "d-flex flex-row gap-2 justify-content-end" }
const _hoisted_7 = ["onClick", "data-kt-indicator"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    "initial-values": _ctx.formData,
    "validation-schema": _ctx.formSchema,
    class: "form w-100 fv-plugins-bootstrap5 fv-plugins-framework",
    ref: "profileForm"
  }, {
    default: _withCtx(({ handleSubmit, values, validate }) => [
      _createElementVNode("form", {
        onSubmit: _withModifiers(($event: any) => (handleSubmit(values, _ctx.submitForm)), ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FormField, {
              required: true,
              containerClass: "flex-fill w-lg-100 w-50 w-sm-100",
              label: "Password",
              name: "password",
              placeholder: "Password",
              type: "password"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FormField, {
              required: true,
              containerClass: "flex-fill w-lg-100 w-50 w-sm-100",
              label: "Confirm Password",
              name: "password_confirmation",
              placeholder: "Confirm Password",
              type: "password"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "btn btn-new-design-secondary align-self-center",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }, " Reset "),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-new-design-primary align-self-center",
            onClick: validate,
            "data-kt-indicator": _ctx.loading ? 'on' : null
          }, " Save ", 8, _hoisted_7)
        ])
      ], 40, _hoisted_1)
    ]),
    _: 1
  }, 8, ["initial-values", "validation-schema"]))
}